<template>
  <v-btn
    v-if="exists"
    :height="size"
    :to="{ name: 'member/home', params: { id: user.id } }"
    :width="size"
    icon
  >
    <v-avatar
      :size="size"
      style="overflow: visible"
    >
      <img :src="user.avatarImage">
    </v-avatar>
  </v-btn>
  <v-avatar
    v-else
    :size="size"
    style="overflow: visible"
  >
    <img :src="avatarImage">
  </v-avatar>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Record } from '@vuex-orm/core'
import { Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ default: '42px' })
  readonly size!: string | number
  @Prop({ default: {} })
  readonly user?: Record

  get exists(): boolean {
    if (!this.user) return false
    if (this.user.id <= 0) return false
    return !this.user.deleted_at
  }

  get avatarImage(): string {
    return this.user ? this.user.avatarImage : null
  }
}
</script>
