<template>
  <v-menu
    :close-on-content-click="false"
    :open-on-hover="visible"
    :value="visible"
    offset-y
    top
    v-bind="$attrs"
    @input="input"
  >
    <template #activator="activator">
      <slot
        name="activator"
        v-bind="activator"
      />
    </template>
    <VueEmojiPicker
      :data="emojiIndex"
      :native="true"
      :picker-styles="pickerStyles"
      class="ma-0"
      emoji="point_up"
      title="Pick your emoji…"
      @select="select"
    />
  </v-menu>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import * as Emoji from 'emoji-mart-vue-fast'
import { emojiIndex, EmojiValue as _EmojiValue } from '@/utils/emoji'

type EmojiValue = _EmojiValue
@Component({
  inheritAttrs: false,
  components: {
    VueEmojiPicker: Emoji.Picker,
  },
})
export default class extends Vue {
  readonly emojiIndex = emojiIndex
  @Prop({ default: false })
  value: boolean

  get visible(): boolean {
    return this.value
  }

  get pickerStyles(): Partial<CSSStyleDeclaration> {
    const style: Partial<CSSStyleDeclaration> = {}
    if (this.$vuetify.breakpoint.xsOnly) {
      style.width = ''
    }
    return style
  }

  @Emit('input')
  input(value: boolean): boolean {
    return value
  }

  @Emit('select')
  select(emoji: EmojiValue): EmojiValue {
    return emoji
  }
}
</script>
