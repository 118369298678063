import Vue from 'vue'
import { DirectiveBinding, DirectiveFunction, DirectiveOptions } from 'vue/types/options'

class VisibilityChange implements DirectiveOptions {
  listener: {
    [key: string]: () => void
  } = {}
  bind: DirectiveFunction = (el: HTMLElement, binding: DirectiveBinding) => {
    const fnName = Math.random().toString(32).substring(2)
    el.dataset.visibilityChange = fnName
    this.listener[fnName] = () => {
      binding.value(document.visibilityState === 'visible')
    }
    document.addEventListener('visibilitychange', this.listener[fnName])
  }
  unbind: DirectiveFunction = (el: HTMLElement) => {
    const fnName = el.dataset.visibilityChange
    document.removeEventListener('visibilitychange', this.listener[fnName])
  }
}

export default Vue.directive('visibility-change', new VisibilityChange())
