export default (content: string | string[], fileName = 'download.csv'): void => {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf])
  let csv: string
  if (Array.isArray(content)) {
    csv = content.join(String.fromCharCode(13, 10))
  } else {
    csv = content
  }
  const blob = new Blob([bom, csv], { type: 'text/csv' })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  link.click()
}
