import Vue from 'vue'
import Component from 'vue-class-component'
import Actions from '@/components/organisms/actions/Actions.vue'

@Component({
  components: {
    Actions,
  },
})
export default class Actable extends Vue {
  showActions = false

  get visibleActions(): boolean {
    return this.showActions
  }

  mounted(): void {
    this.$el.addEventListener('mouseout', this.onMouseOut, true)
    this.$el.addEventListener('mouseover', this.onMouseOver, true)
  }

  beforeDestroy(): void {
    this.$el.removeEventListener('mouseout', this.onMouseOut, true)
    this.$el.removeEventListener('mouseover', this.onMouseOver, true)
  }

  onMouseOver(): void {
    if (this.showActions) return
    this.showActions = true
  }

  onMouseOut(): void {
    this.showActions = false
  }
}
