<template>
  <v-container>
    <h1>404 Not Found</h1>
    <a href="/">Get me out of here!</a>
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class extends Vue {
}
</script>
