<template>
  <v-row
    class="ma-0 pa-0 c-reaction-chip-group flex-wrap"
  >
    <ReactionChip
      v-for="reaction in reactionGroup"
      :key="reaction.value"
      :loading="loading"
      :value="reaction"
      @click="selectReaction(reaction)"
    />
  </v-row>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import ReactionChip from '@/components/organisms/actions/ReactionChip.vue'

@Component({
  components: {
    ReactionChip,
  },
})
export default class ReactionChipGroupComponent extends Vue {
  @Prop({ default: [] })
  readonly value!: Record[]
  @Prop({ default: false })
  readonly loading?: boolean

  get reactionGroup(): Record[] {
    return this.value
  }

  @Emit('click:reaction')
  selectReaction(reaction): string {
    return reaction.value
  }
}
</script>
<style lang="scss" scoped>
.c-reaction-chip-group {
  > * {
    &:not(:first-child) {
      margin-left: 4px;
    }
  }
}
</style>
