<template>
  <v-list
    :class="listClasses"
    :dark="dark"
    :three-line="threeLine"
    :two-line="twoLine"
  >
    <slot />
    <div
      ref="loader"
      class="ma-0 pa-0"
    >
      <v-skeleton-loader
        :style="loaderStyle"
        :type="listItemType"
        :types="skeletonTypes"
      />
    </div>
  </v-list>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { ElementDynamicClass } from '@/interfaces/ElementDynamicClass'

@Component
export default class extends Vue {
  $refs!: {
    loader: HTMLDivElement
  }
  @Prop({ default: false })
  readonly dark?: boolean
  @Prop({ default: false })
  readonly twoLine?: boolean
  @Prop({ default: false })
  readonly threeLine?: boolean
  @Prop({ default: false })
  readonly reverse?: boolean
  @Prop({ default: true })
  readonly loading!: boolean
  @Prop({ default: 'list-item-avatar-three-line' })
  readonly listItemType!: string
  readonly skeletonTypes = {
    'list-item-avatar': 'avatar, text',
  }
  private intersectionObserver: IntersectionObserver

  get listClasses(): ElementDynamicClass {
    const listClass: ElementDynamicClass = {}
    if (this.reverse) {
      listClass['d-flex'] = true
      listClass['flex-column-reverse'] = true
    }
    return listClass
  }

  get loaderStyle(): Partial<CSSStyleDeclaration> {
    return {
      visibility: this.loading ? 'visible' : 'hidden',
    }
  }

  @Emit('infinite')
  infinite(): void {
    return void 0
  }

  async mounted(): Promise<void> {
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        // このコンポーネント自体が画面上に表示されていない場合は、処理を行わない
        const rect = this.$el.getBoundingClientRect()
        if (rect.top >= 0 && rect.bottom <= 0) return
        if (rect.top >= window.innerHeight && rect.bottom >= window.innerHeight) return
        if (rect.left <= 0 && rect.right <= 0) return
        if (rect.left >= window.innerWidth && rect.right >= window.innerWidth) return
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.loading) return
            this.infinite()
          }
        })
      },
      {
        root: null,
        rootMargin: '0%',
        threshold: [0.5],
      },
    )
    this.intersectionObserver.observe(this.$refs.loader)
  }

  beforeDestroy(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.unobserve(this.$refs.loader)
      this.intersectionObserver.disconnect()
    }
  }
}
</script>
<style lang="scss" scoped>
// HACK: Vuetifyのsassをインポートして定義できるはず
.v-skeleton-loader {
  // noinspection CssInvalidPseudoSelector
  :deep(.v-skeleton-loader__avatar) {
    border-radius: 50%;
  }

  // noinspection CssInvalidPseudoSelector
  :deep(.v-skeleton-loader__list-item-avatar) {
    .v-skeleton-loader__avatar {
      width: 32px;
      height: 32px;
    }
  }
}

.theme--dark {
  &.v-skeleton-loader {
    // noinspection CssInvalidPseudoSelector
    > :deep(.v-skeleton-loader__bone) {
      background: transparent;
    }
  }
}
</style>
