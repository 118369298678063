/* eslint camelcase: "off" */
/* eslint @typescript-eslint/naming-convention: "off" */
import { Fields } from '@vuex-orm/core'
import { emojify } from '@/utils/emoji'
import _find from 'lodash/find'
import _get from 'lodash/get'
import Model from '@/store/models/Model'

export const feelings = [
  {
    emoji: '😐',
    value: ':neutral_face:',
    name: '普通',
  },
  {
    emoji: '😄',
    value: ':smile:',
    name: '良い',
  },
  {
    emoji: '😨',
    value: ':fearful:',
    name: '悪い',
  },
]
export const defaultFeeling = _get(
  _find(feelings, (feeling) => feeling.value === ':neutral_face:'),
  'emoji',
)
export default class Feeling extends Model {
  static entity = 'Feeling'
  static _endpoint = 'feelings'

  get emoji(): string {
    return emojify(this['value'])
  }

  static fields(): Fields {
    return {
      id: this.attr(null),
      value: this.attr(null, (value) => (value ? value : defaultFeeling)),
      created_at: this.attr(null),
      updated_at: this.attr(null),
    }
  }
}
