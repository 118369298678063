import { EmojiIndex, store } from 'emoji-mart-vue-fast'
import emojiData from 'emoji-mart-vue-fast/data/all.json'
import { EmojiValue as _EmojiValue } from './types'

type EmojiValue = _EmojiValue
export const emojiIndex = window['emojiIndex'] || new EmojiIndex(emojiData)

export function emojify(value): string {
  const emoji = emojiIndex.findEmoji(value)
  return emoji ? emoji.native : ''
}

export function colonStringify(emoji: EmojiValue): string {
  const skin = `:skin-tone-${store.get('skin') || 1}:`
  return emojiIndex.findEmoji(emoji.colons).unified === emoji.unified ? emoji.colons : `${emoji.colons}${skin}`
}
