<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          :color="color"
          :loading="loading"
          class="px-0"
          outlined
          rounded
          small
          @click="
            $emit('click')
            on.mouseleave()
          "
          @mouseenter="on.mouseenter"
          @mouseleave="on.mouseleave"
        >
          {{ reaction.emoji }}{{ users.length > 99 ? '99+' : users.length }}
        </v-btn>
      </template>
      <span
        v-for="user in users"
        :key="user.id"
        class="c-user__name"
      >{{ user.name }}</span>
    </v-tooltip>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Record } from '@vuex-orm/core'
import Me from '@/store/models/Me'
import _some from 'lodash/some'
import CommunityMe from '@/store/models/communities/Me'

@Component
export default class ReactionChipComponent extends Vue {
  @Prop({ default: {} })
  readonly value!: Record
  @Prop({ default: false })
  readonly loading?: boolean

  get reaction(): Record {
    return this.value
  }

  get currentUser(): Record {
    return Me.query().first() || CommunityMe.query().first()
  }

  get users(): Record[] {
    return this.reaction.users
  }

  get color(): string {
    return _some(this.users, (user) => user.id === this.currentUser.id) ? 'primary' : ''
  }
}
</script>
<style lang="scss" scoped>
.c-user {
  &__name {
    &:not(:first-child) {
      margin-left: 4px;
    }
  }
}
</style>
