import { PluginObject } from 'vue'
import Channels from '@/plugins/vue-channel/channels'

let Vue
const VueChannel: PluginObject<undefined> = {
  install(_Vue) {
    if (Vue) {
      _Vue = Vue
    }
    if (_Vue.prototype.$channels instanceof Channels) return
    _Vue.prototype.$channels = new Channels()
  },
}
export default VueChannel
