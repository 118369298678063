<template>
  <v-container>
    <h1>403 Forbidden</h1>
    <a :href="path === '/' ? '/logout' : '/'">Get me out of here!</a>
  </v-container>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class extends Vue {
  get path(): string {
    return location.pathname
  }
}
</script>
