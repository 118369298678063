<template>
  <v-row
    class="ma-0 pa-0 c-tag-chip-group flex-wrap"
  >
    <v-chip
      v-for="tag in tags"
      :key="tag"
      input-value="true"
      v-bind="$attrs"
    >
      {{ tag }}
    </v-chip>
  </v-row>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class extends Vue {
  @Prop({ default: null })
  readonly value!: string[]

  get tags(): string[] {
    return this.value || []
  }
}
</script>
<style lang="scss" scoped>
.c-tag-chip-group {
  > * {
    &:not(:first-child) {
      margin-left: 4px;
    }
  }
}
</style>
