<template>
  <v-dialog
    :max-width="maxWidth"
    :value="value"
    class="p-croppedImage"
    @input="changeDialog"
    @click:outside="reset"
  >
    <template #activator="{ on }">
      <slot
        :on="on"
        name="activator"
      />
    </template>
    <template #default="dialog">
      <v-card>
        <v-card-title
          class="text-h5 grey lighten-2"
          primary-title
        >
          {{ title }}
        </v-card-title>
        <v-row
          ref="imageCanvas"
          class="ma-0 pa-0 p-croppedImage__image__wrapper"
          justify="center"
        >
          <croppa
            ref="croppa"
            v-model="croppedImage"
            :canvas-color="canvasColor"
            :class="{
              'p-croppedImage__image--round': rounded !== false,
            }"
            :height="height"
            :replace-drop="true"
            :show-remove-button="false"
            :width="width"
            class="p-croppedImage__image"
            placeholder="画像を選択してください"
            @init="selected = false"
            @move="selected = true"
            @zoom="selected = true"
            @file-choose="pickFile"
            @image-remove="selected = false"
            @new-image="selected = true"
          />
        </v-row>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click.stop="cancel(dialog)"
          >
            キャンセル
          </v-btn>
          <v-btn
            :disabled="!selected"
            text
            @click.stop="reset()"
          >
            クリア
          </v-btn>
          <v-btn
            :disabled="!selected"
            color="primary"
            text
            @click.stop="select(dialog)"
          >
            決定
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import _defer from 'lodash/defer'
import VueCroppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'

Vue.use(VueCroppa)
@Component
export default class SelectImageDialogComponent extends Vue {
  $refs!: {
    imageCanvas: HTMLDivElement
    croppa: any // eslint-disable-line @typescript-eslint/no-explicit-any
  }
  @Prop({ default: null })
  readonly value?: boolean
  @Prop({ default: null })
  readonly title!: string
  @Prop({ default: '600px' })
  readonly maxWidth?: string
  @Prop({ default: 600 })
  readonly width?: number
  @Prop({ default: 200 })
  readonly height?: number
  @Prop({ default: null })
  readonly src?: string
  @Prop({ default: false })
  readonly rounded?: boolean
  // NOTE: vue-croppaに型定義ファイルが無いためanyで定義しています。
  croppedImage: any = {} // eslint-disable-line @typescript-eslint/no-explicit-any
  selected = false
  fileName: string
  fileType: string
  canvasColor = null
  private _internalValue = false

  get defaultImage(): string {
    return this.src
  }

  @Watch('value')
  watchValue(): void {
    this.changeDialog(this.value)
  }

  changeDialog(value): void {
    if (this._internalValue === value) return
    if (value) {
      _defer(() => {
        this.resizeCanvas()
        this.$refs.croppa.chooseFile()
      })
    }
    this.input(value)
  }

  @Emit()
  input(value): boolean {
    this._internalValue = value
    return value
  }

  async mounted(): Promise<void> {
    window.addEventListener('resize', this.handleResize)
  }

  beforeDestroy(): void {
    if (window !== undefined) {
      window.removeEventListener('resize', this.handleResize)
    }
  }

  @Emit('remove')
  remove(dialog): void {
    this.hide(dialog)
    return void 0
  }

  @Emit('cancel')
  cancel(dialog): void {
    this.hide(dialog)
    return void 0
  }

  @Emit('select')
  select(dialog): { file: File; dataUrl: string } {
    const dataUrl = this.croppedImage.generateDataUrl(this.fileType)
    const image64Data = dataUrl.substring(dataUrl.indexOf(',') + 1)
    const imageData = atob(image64Data)
    const unit8Array = new Uint8Array(imageData.length)
    unit8Array.forEach((_val, index) => {
      unit8Array[index] = imageData.charCodeAt(index)
    })
    this.hide(dialog)
    return {
      file: new File([unit8Array], this.fileName, {
        type: this.fileType,
      }),
      dataUrl,
    }
  }

  reset(): void {
    this.croppedImage.remove()
  }

  pickFile(file: File): void {
    this.canvasColor = file.type === 'image/png' ? null : '#fafafa'
    this.fileType = file.type
    this.fileName = file.name
  }

  hide(dialog): void {
    dialog.value = false
    _defer(() => {
      this.reset()
    })
  }

  handleResize(): void {
    this.resizeCanvas()
  }

  resizeCanvas(): void {
    if (!this.$refs.imageCanvas) return
    const { clientWidth } = this.$refs.imageCanvas
    const canvas: HTMLCanvasElement = this.$refs.imageCanvas.querySelector('canvas')
    const width = this.width > clientWidth ? clientWidth : this.width < 200 ? 200 : this.width
    canvas.style.width = `${width}px`
    canvas.style.height = `${this.height * (width / this.width)}px`
  }
}
</script>
<style lang="scss" scoped>
.p-croppedImage {
  &__image {
    &__wrapper {
      max-width: 100%;
      background-color: #fafafa;
    }

    overflow: hidden;

    &--round {
      border-radius: 50%;
    }
  }
}
</style>
