import { Consumer, createConsumer, Subscription } from '@rails/actioncable'
import _isNil from 'lodash/isNil'
import _get from 'lodash/get'
import { Model } from '@vuex-orm/core'
import _forEach from 'lodash/forEach'

type Method = (...args) => void
type Methods = { [method: string]: Method }

export interface ChannelInfo {
  name: string
  model?: typeof Model
  methods?: Methods
  data?: { [key: string]: any } // eslint-disable-line @typescript-eslint/no-explicit-any
}

export default class Channels {
  private _channels: {
    [key: string]: Subscription
  } = {}

  subscribe(channels: ChannelInfo[], params?: object): void {
    const consumer: Consumer = createConsumer()
    channels.forEach((channel) => {
      let methods: Methods = {}
      if (!_isNil(channel.model)) {
        methods.received = (data) => {
          if (_isNil(data.action)) return
          if (data.action === 'destroyed') {
            channel.model.delete(data.id)
          } else {
            channel.model.insertOrUpdate({ data })
          }
        }
      }
      methods = Object.assign(methods, channel.methods) as Methods
      const channelName = params ? { channel: channel.name, ...params } : channel.name
      this._channels[channel.name] = consumer.subscriptions.create(channelName, methods)
    })
  }

  unsubscribe(): void {
    const consumer: Consumer = createConsumer()
    _forEach(this._channels, (channel) => {
      consumer.subscriptions.remove(channel)
    })
    this._channels = {}
  }

  get(name: string): Subscription {
    return _get(this._channels, name)
  }
}
