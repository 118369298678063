<template>
  <EmojiPicker
    v-model="visible"
    @select="selectReaction"
  >
    <template #activator="{ on }">
      <v-btn
        small
        v-on="on"
        @click.prevent.stop="visible = true"
      >
        <v-icon left>
          {{ icons.mdiEmoticonExcitedOutline }}
        </v-icon>
        リアクション
      </v-btn>
    </template>
  </EmojiPicker>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit } from 'vue-property-decorator'
import { colonStringify, EmojiValue as _EmojiValue } from '@/utils/emoji'
import { mdiEmoticonExcitedOutline } from '@mdi/js'
import EmojiPicker from '@/components/organisms/EmojiPicker.vue'

type EmojiValue = _EmojiValue
@Component({
  components: {
    EmojiPicker,
  },
})
export default class ReactionActionComponent extends Vue {
  readonly icons = { mdiEmoticonExcitedOutline }
  reaction = false

  get visible(): boolean {
    return this.reaction
  }

  set visible(value: boolean) {
    this.reaction = value
    if (this.reaction) {
      this.$emit('shown')
    } else {
      this.$emit('hidden')
    }
  }

  @Emit('select:reaction')
  selectReaction(emoji: EmojiValue): string {
    this.visible = false
    return colonStringify(emoji)
  }
}
</script>
