<template>
  <v-row
    :class="layoutClass"
    class="c-actions ma-0 pa-0"
  >
    <slot />
  </v-row>
</template>
<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ElementDynamicClass } from '@/interfaces/ElementDynamicClass'

@Component
export default class ActionsComponent extends Vue {
  @Prop({ default: false })
  readonly show?: boolean
  @Prop({ default: false })
  readonly absolute?: boolean

  get layoutClass(): ElementDynamicClass {
    return {
      'c-actions--hidden': !this.show,
      'c-actions--absolute': typeof this.absolute !== 'boolean' || this.absolute,
    }
  }
}
</script>
<style lang="scss" scoped>
.c-actions {
  display: inline-flex;
  max-width: 100%;
  border-radius: 10px;

  &--absolute {
    position: absolute;
    right: 16px;
    bottom: -20px;
    z-index: 4;
    border-radius: 2px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 20%),
    0 6px 10px 0 rgba(0, 0, 0, 14%),
    0 1px 18px 0 rgba(0, 0, 0, 12%);
  }

  &--hidden {
    display: none;
  }

  // noinspection CssInvalidPseudoSelector
  > :deep(button) {
    border-color: #d8d8d8 #d1d1d1 #bababa;
    border-style: solid;
    border-width: thin;
    border-radius: 0;
    box-shadow: none;

    &:first-of-type {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }

    &:not(:first-of-type) {
      border-left-width: 0;
    }

    &:last-of-type {
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }
  }
}
</style>
