<template>
  <v-dialog
    v-model="value"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">お知らせ</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="notices"
        :items-per-page="-1"
        :no-data-text="$t('message.noNotice')"
        disable-sort
        hide-default-footer
        hide-default-header
        @click:row="read"
      >
        <template #top>
          <v-dialog
            v-model="detail"
            :fullscreen="$vuetify.breakpoint.xsOnly"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ _.get(selectedItem, 'subject') }}</span>
              </v-card-title>
              <v-card-text>
                <div v-html="$marked(_.get(selectedItem, 'body'))" />
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  @click="closeDetail"
                >
                  {{ $t('button.close') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="close"
        >
          {{ $t('button.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import Notice from '@/store/models/Notice'
import Me from '@/store/models/Me'
import { Record } from '@vuex-orm/core'
import { DataTableHeader } from 'vuetify'
import { namespace, State } from 'vuex-class'

const NoticeDialog = namespace('noticeDialog')
@Component
export default class extends Vue {
  @State('noticeDialog') noticeDialog
  @NoticeDialog.Action('hide') hide
  detail = false
  selectedItem: Record = null

  get value(): boolean {
    return this.noticeDialog.value
  }

  set value(value: boolean) {
    if (value) return
    this.hide()
  }

  get headers(): DataTableHeader[] {
    return [
      {
        text: '日付',
        value: 'begin_on',
        width: '140px',
      },
      {
        text: '件名',
        value: 'subject',
      },
    ]
  }

  get notices(): Record[] {
    const query = Notice.query()
    query.orderBy('pinned', 'desc')
    query.orderBy('begin_on', 'desc')
    return query.get()
  }

  get currentUser(): Record {
    return Me.query().first()
  }

  async read(item): Promise<void> {
    this.selectedItem = item
    this.detail = true
    const { data } = await this.$http.patch(['notices', item.id].join('/'))
    data.read = true
    await Notice.insertOrUpdate({ data })
    Notice.fetchAll()
  }

  closeDetail(): void {
    this.detail = false
    this.selectedItem = null
  }

  close(): void {
    this.closeDetail()
    this.hide()
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  // noinspection CssInvalidPseudoSelector
  :deep(tr) {
    &:hover {
      cursor: pointer;
    }

    // noinspection CssInvalidPseudoSelector
    :deep(td) {
      &:first-child {
        width: 140px;
        white-space: nowrap;
      }
    }
  }
}
</style>
